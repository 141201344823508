@import '../../../shared';

/////////////////////////////////////////////////////////////////////////////////////////
/*  NESTABLE */

.dd {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
  button {
    outline: none;
  }
}

.dd-list {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
}

.dd-list .dd-list {
  padding-left: rem(30);
}

.dd-collapsed .dd-list {
  display: none;
}

.dd-item,
.dd-empty,
.dd-placeholder {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  min-height: rem(30);
  line-height: rem(30);
}

.dd-handle {
  display: block;
  height: rem(40);
  margin: rem(5) 0;
  padding: rem(5) rem(10);
  text-decoration: none;
  border: 1px solid $gray-3;
  background: #fff;
  border-radius: 3px;
  cursor: move;
  &:hover {
    color: #fff;
    background: $primary;
    border-color: $primary;
  }
}

.dd-item {
  .dd-item-toggle {
    display: block;
    position: relative;
    cursor: pointer;
    float: left;
    width: rem(30);
    height: rem(30);
    margin: rem(5) 0;
    margin-left: 30px;
    padding: 0;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    border: 0;
    background: transparent;
    line-height: rem(33);
    text-align: center;
    font-family: 'FontAwesome', sans-serif;
    font-size: rem(11);
    color: $text;
    &:before {
      content: '\f141';
      display: block;
      position: absolute;
      width: 100%;
      text-align: center;
      text-indent: 0;
    }
  }

  .nestable-item-container {
    display: block;
    min-height: auto;
    color: inherit;
    font-size: inherit;
    margin-bottom: 0;
  }
}

.draggable-placeholder-inner,
.dd-empty {
  margin: rem(5) 0;
  padding: 0;
  min-height: rem(30);
  background: $gray-2;
  border: 1px dashed $gray-3;
  border-radius: 3px;
}

.dd-empty {
  border: 1px dashed $gray-3;
  min-height: rem(100);
  background-color: $white;
}

.dd-dragel {
  position: absolute;
  pointer-events: none;
  z-index: 9999;
  opacity: 0.8;
  list-style: none;
  li {
    list-style: none;
  }
  > .dd-item .dd-handle {
    margin-top: 0;
  }
  .dd-item > button {
    display: none;
  }
  .dd-handle {
    line-height: rem(20);
    min-height: rem(30);
    padding: rem(5) rem(10);
  }
}

.dd3-content {
  display: block;
  height: rem(40);
  margin: rem(5) 0;
  padding: rem(5) rem(10) rem(5) rem(40);
  text-decoration: none;
  border: 1px solid $gray-3;
  background: $white;
  border-radius: 3px;
}

.dd-dragel > .dd3-item > .dd3-content {
  margin: 0;
}

.dd3-item > button {
  margin-left: 30px;
  &.hover {
    color: $black;
  }
}

.dd3-handle {
  position: absolute;
  margin: 0;
  left: 0;
  top: 0;
  cursor: move;
  width: rem(30);
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  border: 1px solid $gray-3;
  background: $gray-2;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  color: $text;
  &:before {
    font-family: 'FontAwesome', sans-serif;
    content: '\f0c9';
    display: block;
    position: absolute;
    left: 0;
    top: -1px;
    width: 100%;
    text-align: center;
    text-indent: 0;
    font-size: rem(14);
    line-height: rem(40);
  }
  &:hover {
    background: $primary;
    & + .dd3-content {
      border-color: $primary;
    }
  }
}

// dark theme
[data-kit-theme='dark'] {
  .dd-handle,
  .dd3-content {
    background: $dark-gray-5;
    border-color: $dark-gray-4;
  }
  .dd-placeholder {
    background: transparent;
    border-color: $dark-gray-4;
  }
  .dd3-handle {
    &:hover {
      background: $primary;
      border-color: $primary;
    }
  }
}
