// Import mixins
@import '../../../../shared';

////////////////////////////////////////////////////////////////////////////////////////////
// DARK THEME STYLES
[data-kit-theme='dark'] {
  .ant-mentions {
    textarea {
      background: $dark-gray-4;
    }
  }
}
