@import 'app/shared/components/vendor/antd/style';
@import 'app/shared/components/vendor/bootstrap/style';
@import 'app/shared/components/vendor/nprogress/style';
@import 'app/shared/components/vendor/chartist/style';
@import 'app/shared/components/vendor/chartist-tooltip-plugin/style';
@import 'app/shared/components/vendor/nestable/style';
@import 'app/shared/components/vendor/core/style';
@import 'app/shared/components/vendor/smart/style';

@import 'app/core/style';

body {
  background-image: url("assets/images/background.jpg");
  background-size: cover;
  background-attachment: fixed;
}

.initial {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.smart-spinner {
  stroke: $primary;
}

section#error {
  display: block;
  text-align: center;
  margin: 3rem;

  h1 {
    font-size: 3rem;
  }

  h2 {
    color: gray;
    font-size: 3rem;
    margin-bottom: 3rem;
  }

  p {
    font-size: x-large;
    margin: 2rem;
  }

  a.btn {
    font-size: large;
  }
}

.layout-modal {
  background: #f2f4f8 url('assets/images/background.jpg') no-repeat fixed !important;
  background-size: cover !important;

  nz-content {
    display: flex;
    justify-content: center;
  }

  smart-system-500 {
    display: block;
    max-width: 32rem;
    width: 100%;

    text-align: center;
    margin: 3rem;

    h1 {
      font-size: 2rem;
      margin-bottom: 1rem;
    }

    div {
      margin-bottom: 1rem;
    }

    a.btn {
      margin: 1rem;
    }
  }
}

h4 {
  display: inline-block;
  margin-left: rem(15);
  margin-right: rem(15);
  font-variant: small-caps;
  color: $primary;
}

div.edit-toolbar {
  display: inline-block;
  margin-left: rem(30);

  button {
    border: none !important;
  }
}

nz-card {
  border-radius: 6px !important;
}

.ant-switch-disabled, .ant-switch-disabled * {
  cursor: default !important;
}

.xng-breadcrumb-root {
  padding: rem(10) rem(25);
  font-size: rem(15);
  color: $secondary;

  .xng-breadcrumb-link {
    color: $gray-6;
    text-decoration: none;
    @include transition-color;

    &-disabled {
      color: $gray-5;
    }

    &:hover {
      color: $blue;
      text-decoration: none;
      @include transition-color;
    }
  }

  .xng-breadcrumb-separator {
    color: $gray-5;
  }
}

.wip-placeholder {
  flex: auto;

  background-color: #f2f4f8 !important;
  background-position: center center !important;
  background-image: url('assets/images/hic_sunt_leones.png') !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
}

.description {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 1rem;
  row-gap: .5rem;

  & > .desc-label {
    text-align: right;
    font-weight: bold;

    &::after {
      content: ":";
    }
  }

  .ant-collapse {
    background: none;

    &-header {
      padding: 0 !important;
    }

    &-content-box {
      padding: 5px !important;

      &:before {
        content: '.';
        display: block;
        width: 0;
        overflow-x: hidden;
        float: left;
        opacity: .25;
        border-left: 2px solid $secondary;
        margin-right: 15px;
      }
    }
  }
}

nz-collapse.error {
  .ant-collapse-header {
    background-color: #ffd4d4;
    color: #bd0909 !important;
  }
}
