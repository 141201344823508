:root {
  --smart-primary: #0000dc;
  --smart-secondary: #4f4f7a;

  --smart-body-text: #595c97;
  --smart-body-bg: #fff;

  --smart-hover-btn-bg: #f2f4f8;

  --smart-main-menu-bg: #fff;
  --smart-main-menu-selected-bg: #0000dc20;
  --smart-main-menu-trigger-color: #fff;

  --smart-warning: #faad15;

}

$primary: var(--smart-primary);
$secondary: var(--smart-secondary);

$text: var(--smart-body-text);
$main-menu-bg: var(--smart-main-menu-bg);
$main-menu-selected-bg: var(--smart-main-menu-selected-bg);
$main-menu-trigger-color: var(--smart-main-menu-trigger-color);
$hover-btn-bg: var(--smart-hover-btn-bg);
