// Import mixins
@import '../../../../shared';

////////////////////////////////////////////////////////////////////////////////////////////
// DARK THEME STYLES
[data-kit-theme='dark'] {
  .ant-skeleton {
    opacity: 0.1;
  }
}
